var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"app flex-row align-items-top"},[_c('div',{staticClass:"container"},[_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"md":"10"}},[_c('b-card',{staticClass:"text-center search-bar"},[_c('b-input-group',[_c('div',{staticClass:"input-group-prepend"},[_c('span',{staticClass:"input-group-text"},[_c('i',{staticClass:"fa fa-search fa-lg"})])]),_c('b-form-input',{directives:[{name:"focus",rawName:"v-focus"}],staticClass:"form-control",attrs:{"id":"prependedInput","size":"lg","type":"text","placeholder":"Type Institution Name to Select"},nativeOn:{"keyup":function($event){return _vm.search($event)}},model:{value:(_vm.searchText),callback:function ($$v) {_vm.searchText=$$v},expression:"searchText"}})],1)],1),_c('b-card',{staticClass:"text-center search-result"},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',[(_vm.loading)?_c('content-placeholders',{attrs:{"rounded":true}},[_c('content-placeholders-text',{attrs:{"lines":15}})],1):_c('b-card',{staticClass:"text-left"},[(_vm.emptyInstitutions)?_c('b-list-group',{staticClass:"text-center"},[_c('b-list-group-item',[_c('i',[_vm._v("No Institutions found ... ")])])],1):_c('b-list-group',_vm._l((_vm.filteredInstitutions),function(institution){return _c('div',{key:institution.id},[_c('b-list-group-item',{class:{
                          selected: institution.id === _vm.selectedInstitution.id
                        },attrs:{"title":institution.alternate_names},on:{"click":function($event){return _vm.setInstitution(institution)}}},[_vm._v(" "+_vm._s(institution.name)+" ")])],1)}),0)],1)],1)])],1)],1),(_vm.selectedInstitutions.length > 0)?_c('b-card',{staticClass:"text-center selected-result",attrs:{"title":"Seleted Institutions"}},[_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',[_c('b-card',{staticClass:"text-left"},[_c('b-list-group',_vm._l((_vm.selectedInstitutions),function(institution){return _c('div',{key:institution.id},[_c('b-list-group-item',{class:{
                          selected: institution.id === _vm.selectedInstitution.id
                        },attrs:{"title":institution.alternate_names},on:{"click":function($event){return _vm.removeInstitution(institution)}}},[_vm._v(" "+_vm._s(institution.name)+" ")])],1)}),0)],1)],1)])],1)],1):_vm._e(),(_vm.selectedInstitutions.length > 0)?_c('b-card',{staticClass:"text-center order-summary",attrs:{"title":"Order Summary"}},[_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"md":"10"}},[_vm._v(" Const per report : ")]),_c('b-col',{staticClass:"text-right",attrs:{"md":"2"}},[_vm._v(" $"+_vm._s(_vm.costPerInstitution)+" ")])],1),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"md":"10"}},[_vm._v(" Const for selected items "+_vm._s(_vm.selectedInstitutions.length)+" * $"+_vm._s(_vm.costPerInstitution)+" : ")]),_c('b-col',{staticClass:"text-right",attrs:{"md":"2"}},[_vm._v(" $"+_vm._s(_vm.selectedInstitutions.length * _vm.costPerInstitution)+" ")])],1),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"md":"10"}},[_vm._v(" Your available free reports count : ")]),_c('b-col',{staticClass:"text-right",attrs:{"md":"2"}},[_vm._v(" "+_vm._s(_vm.remainingFreeReports)+" ")])],1),_c('b-row',[_c('b-col',{staticClass:"text-right",attrs:{"md":"10"}},[_vm._v(" Remaing amount you have to pay : ")]),_c('b-col',{staticClass:"text-right",attrs:{"md":"2"}},[_vm._v(" $"+_vm._s(_vm.remaingAmountToPay)+" ")])],1)],1):_vm._e()],1)],1),_c('b-row',{staticClass:"justify-content-center"},[_c('b-col',{attrs:{"md":"10"}},[_c('b-row',[_c('b-col',{attrs:{"md":"9","sm":"12"}},[_c('b-button',{attrs:{"size":"","variant":"danger","disabled":true}},[_vm._v("Cancel")])],1),_c('b-col',{attrs:{"md":"3"}},[_c('router-link',{staticClass:"btn float-right btn-primary",attrs:{"tag":"button","to":{
                path: 'select_peer_group',
                query: { institution_id: _vm.selectedInstitution.id }
              },"disabled":!_vm.enableContinue}},[_vm._v(" Continue ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }