<template>
  <div class="app flex-row align-items-top">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="10">
          <b-card class="text-center search-bar">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-search fa-lg"></i>
                </span>
              </div>
              <b-form-input
                v-focus
                id="prependedInput"
                class="form-control"
                size="lg"
                type="text"
                placeholder="Type Institution Name to Select"
                @keyup.native="search"
                v-model="searchText"
              >
              </b-form-input>
            </b-input-group>
          </b-card>

          <b-card class="text-center search-result">
            <b-row>
              <b-col md="12">
                <div>
                  <content-placeholders :rounded="true" v-if="loading">
                    <content-placeholders-text
                      :lines="15"
                    ></content-placeholders-text>
                  </content-placeholders>
                  <b-card class="text-left" v-else>
                    <b-list-group v-if="emptyInstitutions" class="text-center">
                      <b-list-group-item
                        ><i>No Institutions found ... </i></b-list-group-item
                      >
                    </b-list-group>
                    <b-list-group v-else>
                      <div
                        v-for="institution in filteredInstitutions"
                        :key="institution.id"
                      >
                        <b-list-group-item
                          @click="setInstitution(institution)"
                          :class="{
                            selected: institution.id === selectedInstitution.id
                          }"
                          :title="institution.alternate_names"
                        >
                          {{ institution.name }}
                        </b-list-group-item>
                      </div>
                    </b-list-group>
                  </b-card>
                </div>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            title="Seleted Institutions"
            class="text-center selected-result"
            v-if="selectedInstitutions.length > 0"
          >
            <b-row>
              <b-col md="12">
                <div>
                  <b-card class="text-left">
                    <b-list-group>
                      <div
                        v-for="institution in selectedInstitutions"
                        :key="institution.id"
                      >
                        <b-list-group-item
                          @click="removeInstitution(institution)"
                          :class="{
                            selected: institution.id === selectedInstitution.id
                          }"
                          :title="institution.alternate_names"
                        >
                          {{ institution.name }}
                        </b-list-group-item>
                      </div>
                    </b-list-group>
                  </b-card>
                </div>
              </b-col>
            </b-row>
          </b-card>

          <b-card
            title="Order Summary"
            class="text-center order-summary"
            v-if="selectedInstitutions.length > 0"
          >
            <b-row>
              <b-col md="10" class="text-right">
                Const per report :
              </b-col>
              <b-col md="2" class="text-right">
                ${{ costPerInstitution }}
              </b-col>
            </b-row>
            <b-row>
              <b-col md="10" class="text-right">
                Const for selected items {{ selectedInstitutions.length }} * ${{
                  costPerInstitution
                }}
                :
              </b-col>
              <b-col md="2" class="text-right">
                ${{ selectedInstitutions.length * costPerInstitution }}
              </b-col>
            </b-row>
            <b-row>
              <b-col md="10" class="text-right">
                Your available free reports count :
              </b-col>
              <b-col md="2" class="text-right">
                {{ remainingFreeReports }}
              </b-col>
            </b-row>
            <b-row>
              <b-col md="10" class="text-right">
                Remaing amount you have to pay :
              </b-col>
              <b-col md="2" class="text-right">
                ${{ remaingAmountToPay }}
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
      <b-row class="justify-content-center">
        <b-col md="10">
          <b-row>
            <b-col md="9" sm="12">
              <b-button size="" variant="danger" :disabled="true"
                >Cancel</b-button
              >
            </b-col>
            <b-col md="3">
              <router-link
                tag="button"
                :to="{
                  path: 'select_peer_group',
                  query: { institution_id: selectedInstitution.id }
                }"
                class="btn float-right btn-primary"
                :disabled="!enableContinue"
              >
                Continue
              </router-link>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'orderInstitution',
  beforeRouteEnter(to, from, next) {
    next(vm => {
      vm.loading = true
      vm.$http
        .get('/api/users/new_order', {
          handleErrors: true
        })
        .then(res => {
          vm.institutions = res.data.banks
          vm.filteredInstitutions = res.data.banks
          vm.loading = false
        })
    })
  },
  data() {
    return {
      loading: false,
      searchText: '',
      selectedInstitution: {},
      selectedInstitutions: [],
      institutions: [],
      costPerInstitution: 1000,
      availableFreeReports: 0,
      remainingFreeReports: 0,
      filteredInstitutions: []
    }
  },
  computed: {
    enableContinue() {
      return this.selectedInstitution.id !== undefined
    },
    emptyInstitutions() {
      return this.institutions.length === 0
    },
    remaingAmountToPay() {
      let chargableReports =
        this.selectedInstitutions.length - this.availableFreeReports
      return chargableReports > 0
        ? chargableReports * this.costPerInstitution
        : 0
    }
  },
  methods: {
    search: function(event) {
      let text = _.lowerCase(event.target.value)
      if (this.text === '') {
        this.filteredInstitutions = this.institutions
      } else {
        this.filteredInstitutions = _.filter(this.institutions, institution => {
          return _.lowerCase(institution.name).indexOf(text) >= 0
        })
      }
    },
    setInstitution: function(institution) {
      this.selectedInstitution = institution
      this.selectedInstitutions.push(institution)
    },
    removeInstitution: function(institution) {
      this.selectedInstitutions.splice(
        this.selectedInstitutions.indexOf(institution),
        1
      )
    }
  }
}
</script>

<style lang="scss" scoped>
.search-bar {
  &.card {
    .card-body {
      padding: 0.25rem;

      .input-group-prepend {
        span {
          background: none;
          border: none;
        }
      }
      input {
        border: initial;
        &:focus {
          box-shadow: none;
        }
      }
    }
  }
}

.search-result,
.selected-result {
  max-height: 33rem;
  &.card {
    margin-top: -1.5rem;
    border-top: 1px solid #d1d4d7;

    .card-body {
      padding: 0.5rem;
      overflow-x: hidden;

      .list-group-item {
        border: none;
        cursor: pointer;

        &.selected {
          background-color: #f0f3f5;
        }

        &:hover {
          background-color: #f0f3f5;
        }
      }
    }
  }
}
.selected-result {
  &.card {
    margin-top: 0;
  }
}
</style>
